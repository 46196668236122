/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setHomeError, leaveSession } from '../actions';

import '../App.css';

import logo from '../assets/images/BorstLogo.png';
import crown from '../assets/images/crown.png';
import remove from '../assets/images/remove.png';


function GameLobby({ match }) {
    const dispatch = useDispatch();
    let history = useHistory();

    const sessionRef = useSelector(state => state.game.sessionRef);
    const isHost = useSelector(state => state.user.isHost);

    // const [homeState, setHomeState] = useState('lobby');
    // const [error, setError] = useState('');
    const [users, setUsers] = useState([]);
    const [gameStatus, setGameStatus] = useState('lobby'); // 'lobby', 'gameStarted', 'gameEnded'

    useEffect(() => {
        if (match.params.id === undefined || firebase.apps.length === 0) {
            history.replace('/');
        } else {
            // get the users
            const unsubscribe = firebase.firestore().collection('sessions').doc(match.params.id)
                .onSnapshot((doc) => {

                    // store if the game is created or not
                    setGameStatus(doc.data().status);

                    // See if you are removed or not
                    if (!doc.data().users.map(user => user.id).includes(firebase.auth().currentUser.uid)) {
                        // store that you have been kicked
                        dispatch(setHomeError('Du ble fjernet fra spillet av av hosten'));
                        // redirect to homepage
                        history.replace('/');
                    } else {
                        setUsers(doc.data().users);
                    }
                });
            
            return () => unsubscribe();
        }
    }, []);

    useEffect(() => {
        if (gameStatus === 'gameStarted' && !isHost) {
            start();
        }
    }, [gameStatus])

    useEffect(() => {
        if (!sessionRef) {
            history.replace('/');
        }
    }, [sessionRef]);

    const removeUser = (user) => {
        console.log('Removing ' + user.name);
        sessionRef.update({
            users: firebase.firestore.FieldValue.arrayRemove(user)
        })
    }

    const start = e => {
        if (e) {
            e.preventDefault();
        }
        
        // dispatch(joinSession(name, code)); // c58SFEBbP3dL31LhJKhh
        if (sessionRef) {
            history.replace(`/game/${sessionRef.id}`)
        } else  {
            history.replace('/');
        }
    }

    const exit = e => {
        if (e) {
            e.preventDefault();
        }

        dispatch(leaveSession(sessionRef));
    }

    return (
        <div className="Container">
            <div className="bacground-ol" />
            <div className="logoContainer">
                <img src={logo} alt="Børst Logo" className="logo" />
                <h2>Karanteneedition</h2>
                <h1>Kode: {match.params.id}</h1>
            </div>
            <div className="lobbyContainer">
                {
                    users.map(user => (
                        <div className="userField" key={user.id}>
                            <div className="userSmallField">
                                {
                                    user.isHost ? <img src={crown} alt="Host" className="userSmallField"/> : ''
                                }
                            </div>
                            <h2 style={{ color: 'black' }}>{user.name}</h2>
                            <div className="userSmallField">
                                {
                                    isHost && !user.isHost ? (
                                        <button onClick={() => removeUser(user)} className="userSmallField">
                                            <img src={remove} alt="Remove" className="userSmallField"/> 
                                        </button>
                                    ) : ''
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
            {
                isHost ? (
                    <div className="buttonContainer">
                        <button className="button button-start" onClick={start}>
                            <h2 style={{ color: 'black' }}>Start</h2>
                        </button> 
                    </div>
                ) : ''
            }
            <div className="buttonContainer">
                <button className="button-exit" onClick={exit}>
                    <h2>Forlat spillet</h2>
                </button> 
            </div>
        </div>
    );
}

export default GameLobby;
