import {
    SET_SLIDE_INDEX,

    // Host
    CREATE_SESSION,
    HOST_SESSION_SUCCESS,
    HOST_SESSION_ERROR,

    // Join
    LOOKING_FOR_SESSION,
    JOIN_SESSION_SUCCESS,
    JOIN_SESSION_ERROR,
    
    // Both
    HOME_ERROR,
    LEAVE_SESSION_SUCCESS,
    LEAVE_SESSION_ERROR
} from '../actions/types';

const INIT_STATE = {
    slideIndex: 0,
    // Host
    creatingSession: false,
    sessionCreated: false, 

    // Join
    lookingForSession: false,
    sessionFound: false,

    // Both
    sessionRef: null,
    error: ''
};

const GameReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_SLIDE_INDEX:
            return { ...state, slideIndex: action.payload };
    
        case CREATE_SESSION:
            return { ...state, creatingSession: true, sessionCreated: false, error: ''}

        case HOST_SESSION_SUCCESS:
            return { ...state, creatingSession: false, sessionCreated: true, sessionRef: action.payload, error: ''}

        case HOST_SESSION_ERROR:
            return { ...state, creatingSession: false, sessionCreated: false, sessionRef: null, error: action.payload}
        
        case LOOKING_FOR_SESSION:
            return { ...state, lookingForSession: true, sessionFound: false, error: '' };
        
        case JOIN_SESSION_SUCCESS:
            return { ...state, lookingForSession: false, sessionFound: true, sessionRef: action.payload, error: '' };

        case JOIN_SESSION_ERROR: 
            return { ...state, lookingForSession: false, sessionFound: false, sessionRef: null, error: action.payload };
        
        case LEAVE_SESSION_SUCCESS:
            return { ...INIT_STATE };

        case LEAVE_SESSION_ERROR:
            return { ...INIT_STATE, error: action.payload };

        case HOME_ERROR:
            return { ...INIT_STATE, error: action.payload };

        default:
            return { ...state };
    }
};

export default GameReducer;