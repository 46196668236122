import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../App.css';
import { joinSession } from '../actions';
import { Loader } from './Loader';

import { borstLightGray } from '../assets/colors';

function JoinGame({ name, back }) {
    const [code, setCode] = useState('');

    const game = useSelector(state => state.game);

    const dispatch = useDispatch();

    const start = e => {
        e.preventDefault();
        dispatch(joinSession(name, code)); // c58SFEBbP3dL31LhJKhh
    }

    return (
        <div className="homeContainer">
            <div className="inputContainer">
                <input type="string" placeholder="Skriv inn koden" className="input" value={code} onChange={(e) => setCode(e.target.value)} />
                { game.error === '' ? '' : <p>{game.error}</p> }
            </div>
            {
                game.lookingForSession ? (
                    <div className="buttonContainer">
                        <Loader loading={game.lookingForSession} color={borstLightGray} />
                    </div>
                ) : (
                    <div className="buttonContainer">
                        <button className="button button-back" onClick={back}>Tilbake</button>
                        <button className="button" onClick={start}>Bli med i spillet</button>
                    </div>
                )
            }
                

        </div>
    );
}

export { JoinGame };