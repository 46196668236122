import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import { createRandomSessionID } from '../assets/functions';

import {
    SET_SLIDE_INDEX,

    // Host
    CREATE_SESSION,
    HOST_SESSION_SUCCESS,
    HOST_SESSION_ERROR,

    // Join
    LOOKING_FOR_SESSION,
    JOIN_SESSION_SUCCESS,
    JOIN_SESSION_ERROR,

    // Both
    HOME_ERROR,
    LEAVE_SESSION_SUCCESS,
    LEAVE_SESSION_ERROR
} from './types';

export const setSlideIndex = (slideIndex) => {
    return {
        type: SET_SLIDE_INDEX,
        payload: slideIndex
    }
}

export const hostSession = (name) => {
    return (dispatch) => {
        dispatch({ type: CREATE_SESSION });


        // Les alle sessions fra databasen
        let sessionsRef = firebase.firestore().collection('sessions');
        let allSessions = sessionsRef.get()
            .then(snapshot => {
                let sessionIDFound = false;
                let sessionID = null;

                // console.log('Snapshot')
                // console.log(snapshot);

                // Finn en ny ID
                const IDs = snapshot.docs.map(session => session.id);
                // console.log('Session IDs:');
                // console.log(IDs)
                while (!sessionIDFound) {
                    sessionID = createRandomSessionID(5);
                    if (!IDs.includes(sessionID)) {
                        sessionIDFound = true;
                    }
                }
                // console.log('The new sessionID:');
                // console.log(sessionID);

                // Gjør en transaction for å lage en ny session
                let newSessionRef = firebase.firestore().collection('sessions').doc(sessionID);

                let transaction = firebase.firestore().runTransaction(t => {
                    return t.get(newSessionRef)
                        .then(doc => {
                            // console.log('The new doc')
                            // console.log(doc);

                            if (doc && !doc.exists) {
                                // Now make a new session
                                // if (doc.maxUsers)
                                // console.log(doc.data());

                                let metaData = {
                                    users: [{
                                        name: name,
                                        isHost: true,
                                        id: firebase.auth().currentUser.uid,
                                    }],
                                    slides: [], // set the slides in the cloud function
                                    currentIndex: 0,
                                    roundsStarted: 0,
                                    roundsEnded: 0,
                                    createTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
                                    updateTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
                                    status: 'lobby' // 'lobby', 'gameStarted', 'gameEnded'
                                };

                                // send the metaData to the session
                                t.set(newSessionRef, metaData);


                                // let userData = {};
                                // userData[firebase.auth().currentUser.uid] = {
                                //     name: name,
                                //     isHost: true
                                // };

                                

                                // create and send the userData to the gameData subcollection
                                // t.collection('gameData').doc('users').set(userData);
                                

                                // Return success
                                return Promise.resolve(newSessionRef);

                            } else {
                                // console.log({ message: 'The session does not exist' });
                                // dispatch({ type: JOIN_SESSION_ERROR, payload: 'The session does not exist'})
                                return Promise.reject('The session already exist');
                            }
                        })
                        .catch(err => { 
                            console.log(err.message);
                            
                            // dispatch({ type: JOIN_SESSION_ERROR, payload: err.message})
                            return Promise.reject(err.message);
                        });
                })
                .then(result => {
                    // console.log('Transaction success', result);
                    dispatch({ type: HOST_SESSION_SUCCESS, payload: result });
                }).catch(err => {
                    console.log('Transaction failure:', err);
                    dispatch({ type: HOST_SESSION_ERROR, payload: err})
                });
            })
            .catch(err => {
                dispatch({ type: HOST_SESSION_ERROR, payload: err})
            });
    }
}

export const joinSession = (name, sessionCode) => { // sessionCode
    return (dispatch) => {
        dispatch({ type: LOOKING_FOR_SESSION });
        if (sessionCode && sessionCode !== "") {
            // console.log('looking for session');

            const sessionRef = firebase.firestore().collection('sessions').doc(sessionCode);
            // let usersRef = firebase.firestore().collection('sessions').doc(sessionCode).collection('gameData').doc('users');

            firebase.firestore().runTransaction(t => {
                return t.get(sessionRef)
                    .then(doc => {
                        // console.log(doc);
                        if (doc && doc.exists) {
                            // The session and user-document exist, now join it

                            // if (doc.maxUsers)
                            if (doc.data().users && doc.data().users.length > 0) {
                                t.update(sessionRef, { 
                                    users: firebase.firestore.FieldValue.arrayUnion({ isHost: false, id: firebase.auth().currentUser.uid, name: name })
                                });
                                return Promise.resolve(sessionRef);
                            } else {
                                // dispatch({ type: JOIN_SESSION_SUCCESS, payload: doc });
                                return Promise.reject('Det er ingen i dette spillet');
                            }
                            // firebase.firestore().collection('sessions').doc(sessionCode).set()
                        } else {
                            // console.log({ message: 'The session does not exist' });
                            // dispatch({ type: JOIN_SESSION_ERROR, payload: 'The session does not exist'})
                            return Promise.reject('Et spill med denne koden finnes ikke');
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        // dispatch({ type: JOIN_SESSION_ERROR, payload: err.message})
                        return Promise.reject(err);
                    });
            })
            .then(result => {
                // console.log('Transaction success', result);
                dispatch({ type: JOIN_SESSION_SUCCESS, payload: result });
            }).catch(err => {
                // console.log('Transaction failure:', err);
                dispatch({ type: JOIN_SESSION_ERROR, payload: err})
            });

            // console.log('The transaction');
            // console.log(transaction);
        } else {
            dispatch({ type: JOIN_SESSION_ERROR, payload: 'Koden kan ikke være tom.' });
        }
    }
}

export const leaveSession = (sessionRef) => {
    return (dispatch) => {
        if (sessionRef) {
            firebase.firestore().runTransaction(t => {
                return t.get(sessionRef)
                    .then(doc => {
                        if (doc && doc.exists) {
                            // The session exist, now leave it
                            if (doc.data().users && doc.data().users.length > 0) {
                                const theUser = doc.data().users.find(urs => urs.id === firebase.auth().currentUser.uid);
                                if (theUser) {
                                    t.update(sessionRef, {
                                        users: firebase.firestore.FieldValue.arrayRemove(theUser)
                                    });
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject('Could not find the user in the session');
                                }
                                
                            } else {
                                return Promise.reject('The session is empty');
                            }
                        } else {
                            return Promise.reject('The session does not exist');
                        }
                    })
                    .catch(err => { 
                        console.log(err.message);
                        return Promise.reject(err.message);
                    });
            })
            .then(result => {
                dispatch({ type: LEAVE_SESSION_SUCCESS });
            }).catch(err => {
                console.log('Transaction failure:', err);
                dispatch({ type: LEAVE_SESSION_ERROR, payload: err})
            });
        } else {
            dispatch({ type: LEAVE_SESSION_ERROR, payload: 'The sessionRef was already empty' });
        }
    }
}

export const setHomeError = (err) => {
    return { type: HOME_ERROR, payload: err };
}
