import React, { useState, useEffect} from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { setHomeError } from '../actions';

import { Loader } from '../components';
import '../App.css';
import { preventDoubleClick } from '../assets/functions';

import logo from '../assets/images/BorstLogo.png';
import exit from '../assets/images/exit.png';
import left from '../assets/images/left-arrow.png';
import right from '../assets/images/right-arrow.png';
import appstore from '../assets/images/AppStore.png';
import playstore from '../assets/images/PlayStore.png';

function Game({ match }) {
    let history = useHistory();

    const sessionRef = useSelector(state => state.game.sessionRef);
    
    // const slideIndex = useSelector(state => state.game.slideIndex);
    const isHost = useSelector(state => state.user.isHost);

    
    const [slides, setSlides] = useState([]);
    const [slideIndex, setSlideIndex] = useState(0);
    const [haveReachedEndslide, setHaveReachedEndslide] = useState(false);

    // const [error, setError] = useState('');

    useEffect(() => {
        if (match.params.id === undefined || firebase.apps.length === 0) {
            history.replace('/');
        } else {
            // start the cloud function
            if (isHost) {
                firebase.firestore().collection('sessions').doc(match.params.id).update({
                    status: 'gameStarted', // 'lobby', 'gameStarted', 'gameEnded'
                    roundsStarted: firebase.firestore.FieldValue.increment(1)
                });
            }

            // get game meta data
            firebase.firestore().collection('sessions').doc(match.params.id)
                .onSnapshot(doc => {

                    // store the slides
                    setSlides(doc.data().slides)

                    // store the slideIndex
                    // console.log(`setting slide index equals to: ${doc.data().currentIndex}`)
                    setSlideIndex(doc.data().currentIndex)

                    // if status changed to 'lobby'
                    if (doc.data().status === 'lobby') {

                        history.push(`/game_lobby/${sessionRef.id}`);
                    }
                });
            
            // get the slides
            // firebase.firestore().collection('sessions').doc(match.params.id).collection('gameData').doc('slides')
            //     .onSnapshot((doc) => {
            //         // store the slides
            //         setSlides(doc.data().gameSlides)
            //     });
        }
    }, []);

    const updateSlideIndex = (inc) => {
        if (isHost) {
            const num = inc ? 1 : -1;
            // do validation here
            if (slideIndex + num > slides.length) {
                // exit game....
                // console.log('Should exit here...');
            } else if (slideIndex + num >= 0) {
                // console.log(`Setting index ${num}`);
                firebase.firestore().collection('sessions').doc(match.params.id)
                    .update({
                        currentIndex: firebase.firestore.FieldValue.increment(num),
                        updateTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    });
            }
        }
    }

    const exitGame = () => {
        if (isHost) {
            
            // Reset noen variabler
            let updatedData = {
                currentIndex: 0,
                slides: [],
                status: 'lobby' // 'lobby', 'gameStarted', 'gameEnded'
            };

            // Sjekk om de er ferdige med runden, lagre det i firestore
            if (haveReachedEndslide) {
                updatedData.roundsEnded = firebase.firestore.FieldValue.increment(1);
            }

            firebase.firestore().collection('sessions').doc(match.params.id)
                .update(updatedData);
        }
    }


    const renderBasic = () => {
        return (
            <div className="BasicContainer">
                <h2>{slides[slideIndex].header}</h2>
                <h3>{slides[slideIndex].text}</h3>
            </div>
        );
    }

    const hasChosen = () => {
        if ('options' in slides[slideIndex]) {
            let found = false;
            slides[slideIndex].options.forEach(option => {
                if (option.votes.includes(firebase.auth().currentUser.uid)) {
                    found = true;
                }
            });
            return found;
        } else {
            return false;
        }
    }

    const vote = (id) => {
        firebase.firestore().runTransaction(t => {
            return t.get(sessionRef)
                .then(doc => {
                    let allSlides = doc.data().slides;
                    const slide = doc.data().slides[slideIndex];
                    if (slide.type === 'dilemma') {
                        // finner riktig slide
                        let theSlide = JSON.parse(JSON.stringify(slide));
                        
                        // finner riktig valg og legger til firebase uid i votes på det valget
                        theSlide.options
                            .find(o => o.id === id)
                            .votes.push(firebase.auth().currentUser.uid);

                        allSlides[slideIndex] = theSlide;

                        t.update(sessionRef, { slides: allSlides });
                        return Promise.resolve();
                    } else {
                        return Promise.reject('Slide is not a Dilemma');
                    }
                })
                .catch((err) => {
                    return Promise.reject(err.message);
                });
        });
    }

    const renderDilemma = () => {
        return (
            <div className="BasicContainer">
                <h2>{slides[slideIndex].header}</h2>
                <h3>{slides[slideIndex].text}</h3>
                <div className="OptionContainer">
                    {
                        hasChosen() ? (
                            slides[slideIndex].options.map((option) => (
                                <div className="OptionBoxVoted" key={option.id}>
                                    <h3>{option.text}</h3>
                                    <div className="OptionCountBox">
                                        <h3>{option.votes.length}</h3>
                                    </div>
                                </div>
                            )
                        )) : (
                            slides[slideIndex].options.map((option) => (
                                <button 
                                    className="OptionBox" 
                                    key={option.id} 
                                    onClick={preventDoubleClick(() => vote(option.id))}
                                >
                                    <h3>{option.text}</h3>
                                </button>
                            )
                        ))
                    }
                </div>
            </div>
                    
        );
    }

    const renderStemmeleken = () => {
        return (
            <div className="BasicContainer">
                <h2>{slides[slideIndex].header}</h2>
                <h3>{slides[slideIndex].text}</h3>
                
            </div>
        );
    }

    const renderEndslide = () => {
        if (!haveReachedEndslide) {
            setHaveReachedEndslide(true);
        }
        // console.log('Have set render end slide, about to return end slide');
        return (
            <div className="BasicContainer">
                <h2>Runden er over ...</h2>
                <h3>... men det er flere spørsmål å oppdage i KaranteneBørst! Du kan også laste ned appen til din mobil, søk opp Børst i AppStore eller PlayStore</h3>
                <div className="PhoneStoreContainer">
                    <img src={appstore} alt="AppStore" className="PhoneStoreImage" />
                    <img src={playstore} alt="PlayStore" className="PhoneStoreImage" />
                </div>
            </div>
        );
    }

    const renderSlide = () => {
        if (slideIndex >= 0 && slideIndex < slides.length) {
            // Dynamisk velg slide-type her...
            switch (slides[slideIndex].type) {
                case 'dilemma': return renderDilemma();
                default: return renderBasic();
            }
        } else if (slideIndex >= slides.length) {
            // console.log('Should render end slide');
            return renderEndslide();
        }
        
    }

    return (
        <div className="GameContainer">
            <div className="TopContainer">
                <img src={logo} alt="Børst Logo" className="logoSmall" />
                {
                    /* Gjøre det mulig for folk å leave? */
                    !isHost ? '' : (
                        <button onClick={exitGame} className="ExitButton">
                            <img src={exit} alt="Exit game" className="ExitImage"/> 
                        </button>
                    )
                }
            </div>
            {
                slides.length <= 0 ? (
                    <div className="BasicContainer">
                        <Loader loading={true} />
                    </div>
                    ) :  renderSlide()
            }
            <div className="BottomContainer">
                <h3>Kode: {match.params.id}</h3>
            </div>
            {
                !isHost ? '' : (
                    <div className="NextLastContainer">
                        <div className="NextLastButtonContainer">
                            {
                                slideIndex === 0 ? '' : (
                                    <button onClick={() => updateSlideIndex(false)} className="NextLastButton">
                                        <img src={left} alt="Last slide" className="NextLastImage"/> 
                                    </button>
                                )
                            }
                        </div>
                        <div className="NextLastButtonContainer">
                            {
                                slideIndex === slides.length ? '' : (
                                    <button onClick={() => updateSlideIndex(true)} className="NextLastButton">
                                        <img src={right} alt="Next slide" className="NextLastImage"/> 
                                    </button>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}

export default Game;
