/* eslint-disable react/prop-types */
import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

export const Loader = ({ size=15, loading, color='white' }) => {
    return (
        <PulseLoader
            size={size}
            color={color}
            loading={loading}
        />
    );
};
