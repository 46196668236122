// --- Users ---
export const SET_USERNAME = 'SET_USERNAME';
export const SET_IS_HOST = 'SET_IS_HOST';

//  --- Game ---
export const SET_SLIDE_INDEX = 'SET_SLIDE';

// Host
export const CREATE_SESSION = 'CREATE_SESSION';
export const HOST_SESSION_SUCCESS = 'HOST_SESSION_SUCCESS';
export const HOST_SESSION_ERROR = 'HOST_SESSION_ERROR';

// Join
export const LOOKING_FOR_SESSION = 'LOOKING_FOR_SESSION';
export const JOIN_SESSION_SUCCESS = 'JOIN_SESSION_SUCCESS';
export const JOIN_SESSION_ERROR = 'JOIN_SESSION_ERROR';

// Both
export const HOME_ERROR = 'HOME_ERROR';
export const LEAVE_SESSION_SUCCESS = 'LEAVE_SESSION_SUCCESS';
export const LEAVE_SESSION_ERROR = 'LEAVE_SESSION_ERROR';
