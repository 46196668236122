import mytext from './slides.js';
import firebase from 'firebase/app';

export const createRandomSessionID = (length=5) => {
    let id = '';
    for (let i = 0; i < length; i++) {
        const randInt = Math.floor(Math.random() * Math.floor(10))
        id += randInt.toString();
    }
    return id;
}

export const preventDoubleClick = (func, wait = 500) => {
    let tapCount = 0;
    let handler;

    return function() {
        if (tapCount === 0) {
            tapCount++;
            func();
        }
        // Clear the previous timeout and set a new one.
        clearTimeout(handler);
        handler = setTimeout(() => (tapCount = 0), wait);
    };
};

export const uploadSlides = () => {

    const lines = mytext.split('\n');
    console.log(lines);

    let slides = [];
    let header = '';

    let idCounter = 0;

    let lastLine = 'test';
    for (var i = 0; i < lines.length; i++) {
        const currentLine = lines[i].trim();
        // console.log(currentLine);
        if (lastLine === '') {
            // Set header
            header = currentLine;
        } else if (currentLine !== '') {
            let type = '';
            switch (header) {
                case 'Dilemma': type = 'dilemma'; break;
                case 'Stemmeleken': type = 'stemme'; break;
                default: type = 'basic'; break;
            }
            // Store the object
            const slide = {
                id: idCounter,
                header: header,
                text: currentLine,
                type: type
            }

            if (type === 'dilemma') {
                const parts = currentLine.split(' ; ');
                slide.text = parts[0];
                slide['options'] = [];
                const options = parts[1].trim().split(',');
                for (let i = 0; i < options.length; i++) {
                    slide.options.push({
                        id: i,
                        text: options[i].trim(),
                        votes: [] // id's of the players
                    });
                }
            } else if (type === 'stemme') {
                slide['votes'] = [];
            }

            slides.push(slide);
            idCounter++;
        }
        lastLine = currentLine;
    }

    firebase.firestore().collection('packs').doc('corona').update({
        slides: slides
    });
}
