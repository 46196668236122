import { combineReducers } from 'redux';

import UserReducer from './UserReducer';
import GameReducer from './GameReducer';

const reducers = combineReducers({
    user: UserReducer,
    game: GameReducer,
});

export default reducers;