import React, { useEffect} from 'react';
import '../App.css';

function About() {
    useEffect(() => {
        // add event listener

        // return () => { // remove event listener }
    }, [])

    return (
        <div className="App">
            <h1>About</h1>
        </div>
    );
}

export default About;
