import {
    SET_USERNAME,
    SET_IS_HOST,
} from '../actions/types';

const INIT_STATE = {
    userData: {
        name: '',
        id: '',
        isHost: false
    },
    name: '',
    isHost: ''
};

const UserReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_USERNAME:
            return { ...state, name: action.payload, userData: { ...state.userData, name: action.payload} };
    
        case SET_IS_HOST:
            return { ...state, isHost: action.payload, userData: { ...state.userData, isHost: action.payload} };

        default:
            return { ...state };
    }
};

export default UserReducer;