import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUsername, setIsHost, hostSession, setHomeError } from '../actions';

import '../App.css';
import {
    JoinGame,
    // HostGame,
    Loader,
} from '../components';


import logo from '../assets/images/BorstLogo.png';
import { borstLightGray } from '../assets/colors';

function Home() {
    const [homeState, setHomeState] = useState('lobby');
    // const [error, setError] = useState('');

    const dispatch = useDispatch();
    let history = useHistory();

    const name = useSelector(state => state.user.name);

    const sessionRef = useSelector(state => state.game.sessionRef);
    useEffect(() => {
        if (sessionRef !== null) {
            // redirect to game_lobby
            history.push(`/game_lobby/${sessionRef.id}`);
        }
    }, [sessionRef]);

    const error = useSelector(state => state.game.error);
    const isCreatingSession = useSelector(state => state.game.creatingSession);

    const buttonHandler = (newState) => {
        if (name === '') {
            dispatch(setHomeError('Du må skrive inn et navn først'))
        } else {
            firebase.auth().currentUser.updateProfile({
                displayName: name
            });

            setHomeState(newState);

            if (newState === 'join') {
                dispatch(setIsHost(false));
            } else if (newState === 'host') {
                dispatch(setIsHost(true));

                // make a session in firebase
                dispatch(hostSession(name));
            }
        }
    };

    return (
        <div className="Container">
            <div className="bacground-ol" />
            <div className="logoContainer">
                <img src={logo} alt="Børst Logo" className="logo" onClick={() => history.push('/')} />
                <h2 className="Karanteneedition">Karanteneedition</h2>
            </div>
            { 
                homeState === 'join' ? <JoinGame name={name} back={() => setHomeState('lobby')} /> : (
                    <div className="homeContainer">
                        <div className="inputContainer">
                            <input type="string" placeholder="Navn" className="input" value={name} onChange={(e) => dispatch(setUsername(e.target.value))} />
                            { error === '' ? '' : <p>{error}</p> }
                        </div>
                        { 
                            isCreatingSession ? (
                                <div className="buttonContainer">
                                    <Loader loading={isCreatingSession} color={borstLightGray} />
                                </div>
                            ) : (
                                <div className="buttonContainer">
                                    <button className="button" onClick={() => buttonHandler('host')}>Start et nytt spill</button>
                                    <button className="button" onClick={() => buttonHandler('join')}>Bli med i et spill</button>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
}

export default Home;
