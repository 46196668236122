import {
    SET_USERNAME,
    SET_IS_HOST,
} from './types';

export const setUsername = (name) => {
    return {
        type: SET_USERNAME,
        payload: name
    }
};export const setIsHost = (isHost) => {
    return {
        type: SET_IS_HOST,
        payload: isHost
    }
}

// export const setFirebaseUser = (user) => {
//     return {
//         type: SET_FIREBASE_USER,
//         payload: user
//     }
// }