import React, { useEffect} from 'react';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';

import './App.css';
// import Nav from './Nav';
import Home from './pages/Home';
import GameLobby from './pages/GameLobby';
import Game from './pages/Game';
import About from './pages/About';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setUsername } from './actions';

import { uploadSlides } from './assets/functions';


function App() {

    // Sett denne til true hvis du vil laste opp nye slides!
    // NB: alle de gamle blir slettet...
    const upload = false;

    // const name = useSElector(state => state.user.name)
    const dispatch = useDispatch();

    useEffect(() => {
        // Add event listeners

        // Some browser-based IDEs cleverly reload the app without destroying the
        // Firebase instance. Check for this case to avoid initializing the same
        // application twice (which throws an error).
        if (firebase.apps.length === 0) {
            // Your web app's Firebase configuration
            var firebaseConfig = {
                apiKey: "AIzaSyCgZEw5ClMMKb7dmJDu6_Zugh2rktFCKRM",
                authDomain: "blur-web.firebaseapp.com",
                databaseURL: "https://blur-web.firebaseio.com",
                projectId: "blur-web",
                storageBucket: "blur-web.appspot.com",
                messagingSenderId: "294698851257",
                appId: "1:294698851257:web:a9770de4ecbb32fa0e7468",
                measurementId: "G-VGP0RV7E8X"
            };
            // Initialize Firebase
            firebase.initializeApp(firebaseConfig);
            firebase.analytics();
        }
    
        firebase.auth().onAuthStateChanged((user) => { 
            // console.log('firebase auth changed:');
            // console.log(user);
            if (user && user.displayName !== null) {
                dispatch(setUsername(user.displayName));
                if (upload) {
                    uploadSlides(); 
                }
            }
        });
    

        firebase.auth().signInAnonymously()
            .catch((e) => console.error(e));
        
        // UserApiConfig.startListeningForChanges().then(
        //     () => this.setState({ userApiIsLoading: false }));

        // return () => { // remove event listener }
    }, [dispatch, upload])

    // <Nav />

    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route path="/" exact component={Home}  />
                    <Route path="/about" component={About} />
                    <Route path="/game_lobby/:id" component={GameLobby} />
                    <Route path="/game/:id" component={Game} />
                </Switch>
            </div>
        </Router>
        
    );
}

export default App;
